import { Component as ReactComponent, ErrorInfo, ReactNode } from 'react';
import * as Sentry from '@sentry/nextjs';

// Custom ErrorBoundary helps track and debug uncaught 500 errors being thrown from components. Data errors should be handler already, but this catches the rest.
class ErrorBoundary extends ReactComponent<
  { children: ReactNode; message?: string },
  { errored: boolean }
> {
  constructor(props: { children: ReactNode; message?: string }) {
    super(props);
    this.state = { errored: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { errored: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('App caught ', error.name, ' for ', error.message);
    console.error('Error stack - ', error.stack);
    console.error('Component stack: ', errorInfo?.componentStack);
    Sentry.captureException(error);
  }

  render() {
    if (this.state.errored) {
      const message =
        this.props.message ??
        'Pahoittelut, jotain meni pieleen - Apologies, something went wrong.';
      // You can render any custom fallback UI TODO: improve what should be shown here. Keep in mind that eg. Chakra cant be used if I remember correctly.
      // NOTE: Probably best if not translated. The translation lib itself could cause the 500 and so render this useless.
      // TODO: add multiple layers to errorboundaries of which some is translated
      return !!message && <h1>{message}</h1>;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
