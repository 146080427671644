import nextI18nextConfig from 'next-i18next.config';
import { useRouter } from 'next/router';

type Logo = {
  src: string;
  width: number;
  height: number;
};

export type Config = {
  LOCALE: string;
  DOMAIN: string;
  PUBLIC_HOST_URL: string;
  LOCALE_BASE_URL: string;
  SEO_ENABLED: boolean;
  SERVICE_LOGOS: Record<'png' | 'svg', Logo>;
  OG_SHARE_IMAGE: string;
  SERVICE_NAME: string;
};
export type AppContext = {
  locale?: string;
};

const getConfig = ({ locale }: AppContext): Config => {
  const { i18n } = nextI18nextConfig;

  // get the domain for the given locale
  // or default to the default domain
  const validLocale =
    i18n.locales.find((l) => l === locale) ?? i18n.defaultLocale;

  const domainConfig = i18n.domains.find(({ locales }) =>
    locales.includes(validLocale),
  );

  if (!domainConfig) {
    throw new Error(`No site config found for locale ${validLocale}`);
  }

  // The domainURL is the root url for the domain, i.e.
  // https://www.metsatilat.fi or https://www.skogsfastigheter.fi
  // It doesn't take the locale into account
  // (i.e. English domainUrl === Finnish domainUrl)
  const { domainURL, domain } = domainConfig;

  // Construct a locale-aware base url, i.e. the root url for the current locale
  // For localhost it's http://localhost:3000/<locale>
  // For Finnish it's "https://www.metsatilat.fi"
  // For Swedish it's "https://www.skogsfastigheter.fi"
  // For English it's "https://www.metsatilat.fi/en"
  let localeBaseURL = domainURL;
  if (domain.includes('localhost') && validLocale !== 'fi') {
    localeBaseURL = `${domainURL}/${validLocale}`;
  } else if (validLocale === 'en') {
    localeBaseURL = `${domainURL}/en`;
  }

  const logoBaseUrl = `${domainURL}/${
    validLocale === 'sv' ? 'skogsfastigheter' : 'metsatilat'
  }-logo`;

  const logos: Record<string, Record<'png' | 'svg', Logo>> = {
    fi: {
      svg: {
        src: `${logoBaseUrl}.svg`,
        width: 147,
        height: 29,
      },
      png: {
        src: `${logoBaseUrl}-transparent.png`,
        width: 147,
        height: 29,
      },
    },
    en: {
      svg: {
        src: `${logoBaseUrl}.svg`,
        width: 147,
        height: 29,
      },
      png: {
        src: `${logoBaseUrl}-transparent.png`,
        width: 147,
        height: 29,
      },
    },
    sv: {
      svg: {
        src: `${logoBaseUrl}.svg`,
        width: 202,
        height: 29,
      },
      png: {
        src: `${logoBaseUrl}-transparent.png`,
        width: 202,
        height: 29,
      },
    },
  };

  return {
    SERVICE_NAME:
      validLocale === 'sv' ? 'Skogsfastigheter.fi' : 'Metsätilat.fi',
    LOCALE: validLocale,
    DOMAIN: domain,
    PUBLIC_HOST_URL: domainURL,
    LOCALE_BASE_URL: localeBaseURL,
    SEO_ENABLED: !domain.includes('staging') && !domain.includes('localhost'),
    SERVICE_LOGOS: logos[validLocale],
    OG_SHARE_IMAGE: `${domainURL}/${
      validLocale === 'sv' ? 'skogsfastigheter' : 'metsatilat'
    }.png`,
  };
};

export const useConfig = () => {
  const { locale } = useRouter();
  return getConfig({ locale });
};

export default getConfig;
