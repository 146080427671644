import { Integrations } from '@sentry/browser';
import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const DEPLOYMENT_ENVIRONMENT =
  process.env.DEPLOYMENT_ENVIRONMENT ||
  process.env.NEXT_PUBLIC_DEPLOYMENT_ENVIRONMENT ||
  'development';

Sentry.init({
  dsn: SENTRY_DSN,
  autoSessionTracking: false,
  environment: DEPLOYMENT_ENVIRONMENT,
  integrations: [
    new Integrations.Breadcrumbs({
      console: false,
    }),
  ],
});
